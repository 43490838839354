import React, { useEffect } from "react"
import styled from "styled-components"
import { get } from "lodash"

import jetGearLogo from "../imgs/jet-gear-blue.svg"
// import promoIcon from "../imgs/promo-icon.svg"
import creditIcon from "../imgs/credit-dollor-icon.svg"
import blueCheckSrc from "../imgs/blue-check.svg"

const CheckoutContainer = styled.div`
  // padding-left: 4%;
  // flex: 1;

  @media screen and (max-width: 960px) {
    width: 90%;
    padding: 20px 0;
    margin: 0 auto;
  }

  .div1 {
    margin-top: 120px;
    padding: 30px 20px;
    display: flex;
    flex-flow: column;
    background-color: white;
    box-shadow: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px,
      rgba(65, 155, 249, 0) 0px 0px 0px 0px,
      rgba(65, 155, 249, 0.08) 0px 12px 50px 0px,
      rgba(158, 158, 163, 0.25) 0px 2px 10px 0px;
    border-radius: 8px;
    padding-bottom: 16px;

    .price-breakdown {
      .subtotal-wrapper {
        border: 0.5px solid #dadce0;
        border-radius: 4px;
      }
    }

    .proxy-type-ip-logo {
      @media (max-width: 960px) {
        max-width: 20px;
      }
    }

    .proxy-col-1 {
      width: 10%;
      padding: 25px 5px;
    }

    .proxy-col-2 {
      @media (max-width: 960px) {
        width: 65%;
      }
      @media (min-width: 961px) {
        width: 25%;
      }
      padding: 5px 15px;
    }
    .proxy-col-3 {
      font-size: 13px;
      @media (max-width: 960px) {
        display: none;
      }
      width: 40%;
      padding: 25px 10px 5px 10px;
    }
    .proxy-col-4 {
      width: 25%;
      padding: 25px 5px 5px 5px;
    }
  }

  .div2 {
    display: flex;
    flex-flow: row;
    padding: 20px 20px 0px 20px;

    .total-col-1 {
      width: 10%;
      padding: 5px;
    }
    .total-col-2 {
      width: 25%;
      padding: 5px 15px;
      display: flex;
      flex-flow: column;
    }
    .total-col-3 {
      width: 40%;
      padding: 5px;
    }
    .total-col-4 {
      width: 25%;
      padding: 5px;
    }
  }

  span {
    display: inline-block;
  }
  .grey-color {
    color: #9ba1aa;
  }
  .blue-color {
    color: #419bf9;
  }
  .green-color {
    color: #66aabb;
  }
  .red-color {
    color: #ff6363;
  }

  .text-bold {
    font-weight: 700;
  }

  .total-col-4,
  .proxy-col-4:nth-child(n + 3) {
    text-align: right;
  }

  .coupon-input-wrapper {
    .coupon-input-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      #coupenCodeInput {
        width: 100%;
        color: #586271;
        border: 1px solid transparent;
        outline: none !important;
        padding: 5px 20px;
        font-size: 14px;
        transition: all 0.15s cubic-bezier(0.8, 0.3, 0.45, 0.95);
        line-height: 24px;
        border-radius: 6px;
        letter-spacing: 0.2px;
        border-color: rgba(25, 35, 50, 0.1);
        box-sizing: border-box;
      }
      .check-mark-container {
        width: 20px;
      }
    }
    .button {
      border-radius: 12px;
      border: 1px transparent;

      background-color: #667af4;
      color: #ffffff;
      padding: 10px;
      cursor: pointer;
    }
    .button:disabled {
      cursor: not-allowed;
      background-color: #c4c7ce;
    }
    .button--remove {
      background-color: #dc2626;
    }
  }
`
const ItemRow = styled.div`
  display: flex;
  flex-flow: row;
  padding: 20px;
  margin: 0px 10px 0px 20px;
  padding: 20px 0px;
  border-bottom: 0.5px solid #dadce0;

  &:last-child {
    border-bottom: none;
  }

  p {
    margin: 0;
  }
`

const ExtraRow = styled(ItemRow)`
  padding: 20px 0px;

  &:nth-child(3) {
    border-top: 1px solid #dadce0;
  }

  .proxy-col-4:nth-child(2) {
    width: 65%;
    padding: 25px 15px 0;
  }

  @media (min-width: 961px) {
    .dollor-icon {
      height: 30px;
      margin-top: -5px;
    }
  }
`

export default function CheckoutPriceCard(props) {
  const {
    subTotal,
    credit,
    selectedRota,
    selectedType,
    total,
    selectedBillingCycle,
    multiLocationProductDetail,
    couponCode,
    onCouponCodeChange,
    applyingCouponCode,
    applyCouponCode,
    isCouponApplied,
    removeCouponCode,
    isPaymentSuccess,
  } = props

  useEffect(() => {
    props.pointsStore.fetchData()
  }, [props.pointsStore])

  if (!selectedType || !selectedRota) return null

  const rotaDescr = get(selectedRota, "shortDescription")

  return (
    <CheckoutContainer>
      <div className="div1">
        <div className="price-breakdown">
          <div className="subtotal-wrapper">
            {!multiLocationProductDetail && (
              <>
                <ItemRow>
                  <div className="proxy-col-1">
                    <img
                      alt="proxy-type-logo"
                      className="proxy-type-ip-logo"
                      src={selectedType.imageUrl}
                    ></img>
                  </div>
                  <div className="proxy-col-2">
                    <p className="grey-color" style={{ paddingBottom: "10px" }}>
                      Proxy Type
                    </p>
                    <p className="blue-color text-bold">{selectedType.title}</p>

                    <div style={{ marginTop: "20px", fontSize: "13px" }}>
                      {props.isMobile && (
                        <>
                          {selectedType.servicesOffered
                            .split(",")
                            .map((elem, idx) => (
                              <p key={idx} className="green-color text-bold">
                                {elem}
                              </p>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="proxy-col-3">
                    {selectedType.servicesOffered
                      .split(",")
                      .map((elem, idx) => (
                        <p key={idx} className="green-color text-bold">
                          {elem}
                        </p>
                      ))}
                  </div>
                  <div className="proxy-col-4">
                    <p>
                      <span className="text-bold">
                        $
                        {Number(
                          (selectedBillingCycle === "weekly"
                            ? selectedType.weeklyPrice
                            : selectedType.price) || 0
                        ).toFixed(2)}
                      </span>
                      <span className="grey-color">
                        &nbsp;/&nbsp;
                        {selectedBillingCycle === "weekly" ? "week" : "month"}
                      </span>
                    </p>
                  </div>
                </ItemRow>

                <ItemRow>
                  <div className="proxy-col-1">
                    <img
                      alt="proxy-ip-logo"
                      className="proxy-type-ip-logo"
                      src={jetGearLogo}
                    ></img>
                  </div>
                  <div className="proxy-col-2">
                    <p className="grey-color" style={{ paddingBottom: "10px" }}>
                      IP Rotation
                    </p>
                    <p className="blue-color text-bold">{selectedRota.title}</p>

                    <div style={{ marginTop: "20px", fontSize: "13px" }}>
                      {props.isMobile && (
                        <p className="green-color text-bold">{rotaDescr}</p>
                      )}
                    </div>
                  </div>
                  <div className="proxy-col-3">
                    <p className="green-color text-bold">{rotaDescr}</p>
                  </div>
                  <div className="proxy-col-4">
                    <p>
                      <span className="text-bold">
                        $
                        {Number(
                          (selectedBillingCycle === "weekly"
                            ? selectedRota.weeklyPrice
                            : selectedRota.price) || 0
                        ).toFixed(2)}
                      </span>
                      <span className="grey-color">
                        &nbsp;/&nbsp;
                        {selectedBillingCycle === "weekly" ? "week" : "month"}
                      </span>
                    </p>
                  </div>
                </ItemRow>
              </>
            )}

            {multiLocationProductDetail && (
              <ItemRow>
                <div className="proxy-col-1">
                  {/* <img
                    alt="proxy-type-logo"
                    className="proxy-type-ip-logo"
                    src={selectedType.imageUrl}
                  ></img> */}
                </div>
                <div className="proxy-col-2">
                  <p className="grey-color" style={{ paddingBottom: "10px" }}>
                    Type
                  </p>
                  <p className="blue-color text-bold">
                    {multiLocationProductDetail.title}
                  </p>

                  <div style={{ marginTop: "20px", fontSize: "13px" }}>
                    {props.isMobile && (
                      <>
                        {multiLocationProductDetail.servicesOffered
                          .split(",")
                          .map((elem, idx) => (
                            <p key={idx} className="green-color text-bold">
                              {elem}
                            </p>
                          ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="proxy-col-3">
                  {multiLocationProductDetail.servicesOffered
                    .split(",")
                    .map((elem, idx) => (
                      <p key={idx} className="green-color text-bold">
                        {elem}
                      </p>
                    ))}
                </div>
                <div className="proxy-col-4">
                  <p>
                    <span className="text-bold">
                      $
                      {Number(
                        (selectedBillingCycle === "weekly"
                          ? multiLocationProductDetail.weeklyPrice
                          : multiLocationProductDetail.price) || 0
                      ).toFixed(2)}
                    </span>
                    <span className="grey-color">
                      &nbsp;/&nbsp;
                      {selectedBillingCycle === "weekly" ? "week" : "month"}
                    </span>
                  </p>
                </div>
              </ItemRow>
            )}

            <ItemRow className="coupon-input-wrapper">
              <div className="proxy-col-1"></div>
              <div className="proxy-col-2">
                <h4 className="text-bold" style={{ paddingLeft: "10px" }}>
                  Coupon Code
                </h4>
              </div>
              <div className="proxy-col-3">
                <div className="coupon-input-container">
                  <input
                    value={couponCode || ""}
                    id="coupenCodeInput"
                    type="text"
                    disabled={isCouponApplied}
                    placeholder="Enter code"
                    onChange={e => {
                      onCouponCodeChange(e.target.value)
                    }}
                  />
                  {isCouponApplied && (
                    <div className="check-mark-container">
                      <img src={blueCheckSrc} alt="" />
                    </div>
                  )}
                </div>
              </div>
              <div className="proxy-col-4">
                {!isPaymentSuccess && (
                  <p>
                    {isCouponApplied ? (
                      <button
                        className="button button--remove"
                        onClick={removeCouponCode}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        disabled={
                          applyingCouponCode ||
                          !couponCode ||
                          !couponCode.length
                        }
                        className="button button--apply"
                        onClick={applyCouponCode}
                      >
                        Apply
                      </button>
                    )}
                  </p>
                )}
              </div>
            </ItemRow>

            <ItemRow>
              <div className="proxy-col-1"></div>
              <div className="proxy-col-2">
                <h4 className="text-bold" style={{ paddingLeft: "10px" }}>
                  Subtotal
                </h4>
              </div>
              <div className="proxy-col-3"></div>
              <div className="proxy-col-4">
                <p>
                  <span className="text-bold">
                    ${Math.max(subTotal, 0).toFixed(2)}
                  </span>
                </p>
              </div>
            </ItemRow>
          </div>
          {/* {discount > 0 && (
            <ExtraRow>
              <div className="proxy-col-1">
                <img
                  alt="Promo"
                  className="proxy-type-ip-logo"
                  src={promoIcon}
                ></img>
              </div>
              <div className="proxy-col-4">
                <p className="blue-color text-bold">
                  Promo - {props.promo.coupon.name}
                </p>
              </div>
              <div className="proxy-col-4">
                <p>
                  <span className="text-bold red-color">
                    -${discount.toFixed(2)}
                  </span>
                </p>
              </div>
            </ExtraRow>
          )} */}
          {parseFloat(credit) > 0 && (
            <ExtraRow>
              <div className="proxy-col-1">
                <img
                  alt="credit"
                  className="proxy-type-ip-logo dollor-icon"
                  src={creditIcon}
                ></img>
              </div>
              <div className="proxy-col-4">
                <p
                  className="blue-color text-bold"
                  style={{ fontSize: "14px" }}
                >
                  MountProxies credit applied to this purchase
                </p>
              </div>
              <div className="proxy-col-4">
                <p>
                  <span className="text-bold">${credit.toFixed(2)}</span>
                </p>
              </div>
            </ExtraRow>
          )}
        </div>
      </div>
      <div className="div2">
        <div className="total-col-1"></div>
        <div className="total-col-2">
          <h4 className="text-bold" style={{ paddingLeft: "10px" }}>
            Total
          </h4>
        </div>
        <div className="total-col-3"></div>
        <div className="total-col-4">
          <p>
            <span className="text-bold">${Math.max(total, 0).toFixed(2)}</span>
          </p>
        </div>
      </div>
    </CheckoutContainer>
  )
}
