/* eslint-disable no-console */
import React, { useCallback, useState, useEffect } from "react"
// import { navigate } from "gatsby"
import styled from "styled-components"
import { get } from "lodash"
import io from "socket.io-client"
import imgIcon from "../imgs/warning.png"
import authStore from "../stores/auth"
import {
  createSubscriptionPromise,
  createStripeCheckout,
  createCoinbaseCheckout,
  createNMICheckout,
  createCoinifyCheckout,
  createCryptomusCheckout,
  createBitpayCheckout,
  createNowPaymentsCheckout,
  createBlockonomicsCheckout,
} from "../utils/CallAPIs"
import { toastSuccess, toastError } from "../utils/myToasts"
import debounce from "../utils/debounce"

// import coinbaseLogo from "../imgs/coinbase.png"
// import stripeIcon from "../imgs/stripe-icon-blue.svg"

import useStripe from "../hooks/useStripe"

import CryptoActValidationModal from "./CryptoActValidationModal"
import PayWithCardModal from "./PayWithCardModal"
import { METHODS } from "../pages/checkout"

// const gateway = window.Gateway.create(
//   "checkout_public_Qq4KVQd7j6t6JuF7pvf27w5T9SergXZa"
// )
// const threeDS = gateway.get3DSecure()
// console.log('----three ds----', threeDS);

const Container = styled.div`
  // background-color: ${props => (props.blueButton ? "#FFFFFF" : "#667AF4")};
  // overflow: hidden;
  // border: 0.5px solid #667af4;
  // border-radius: 10px;
  // display: flex;
  // justify-content: center;
  // padding: 12px;

  // box-shadow: 0 0 0 1px hsla(216, 33.3%, 14.7%, 0.1),
  //   0 1px 3px 0 hsla(216, 12.4%, 39.4%, 0.06);

  // width: 100%;
  // @media screen and (max-width: 960px) {
  //   width: 90%;
  //   margin: 20px auto;
  //   float: none;
  // }
  // line-height: ${props => (props.isMobile ? "48px" : "32px")};
  // margin-top: ${props => (props.stripeEnabled ? "40px" : "20px")};
  // margin-left: ${props => (props.isMobile ? "5%" : "0px")};
  // margin-right: ${props => (props.isMobile ? "5%" : "0px")};

  // > * {
  //   width: 50%;
  // }

  div {
    display: flex;
    justify-content: center;
  }

  div img {
    height: 50px;
  }
`

const MyButton = styled.button`
  // background-color: ${props => (props.disabled ? "#5a59ef" : "#667af4")};
  background-color: ${props =>
    props.disabled ? "#D3D3D3" : props.blueButton ? "#667AF4" : "#FFFFFF"};
  color: ${props => (props.blueButton ? "#FFFFFF" : "#419BF9")};
  border: 0.5px solid ${props => (props.disabled ? "#D3D3D3" : "#667af4")};
  letter-spacing: 0.6px;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  border-radius: 10px;
  margin: 0;
  cursor: ${props => (props.disabled ? "progress" : "pointer")};
  font-weight: 700;
  font-size: 17px;
`

const CoinbaseDisclaimerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  background: #f5f6fc;
  color: #667af4;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 14px 38px 14px 15px;
  // margin-top: 20px;
  text-align: left;
  cursor: pointer;
  transition: all 0.35s;
  line-height: 1.5;
  .warning-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  span {
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    margin: 20px auto 0;
    float: none;
  }
`

const getProfile = debounce(authStore.getProfile)
export const CoinbaseDisclaimer = () => {
  return (
    <CoinbaseDisclaimerContainer>
      <img src={imgIcon} className="warning-icon" alt="Delete" />
      <span>Transactions made via Binance are not supported</span>
    </CoinbaseDisclaimerContainer>
  )
}
let captchaRef = null
let _widgetID = undefined
export const MyPurchaseProxyButton = props => {
  const {
    selectedFeatures,
    locationID,
    onSuccess,
    multilocationId,
    selectedBillingCycle,
    recurringPayment = false,
    couponId,
    couponCode,
    couponCodeDetails,
  } = props

  const { purchasing, setPurchasing } = props
  const stripe = useStripe()
  const [greToken, setGreToken] = useState(null)
  const mpEmail = get(authStore, "state.user.email", "email not found")
  const [cryptoActivationModal, setCryptoActivationModal] = useState(false)
  const [payWithCardModel, setPayWithCardModel] = useState(false)
  const [creditCardPaymentDetails, setCreditCardPaymentDetails] = useState(null)

  useEffect(() => {
    if (creditCardPaymentDetails) {
      handleClickPurchase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditCardPaymentDetails])

  const handleSuccess = () => {
    toastSuccess("Purchase successful!")
    getProfile()
    onSuccess()
  }

  const handleRecaptchaV2Response = greToken => {
    createSubscription(greToken, true)
    setGreToken(greToken)
  }
  const renderRecaptchaV2 = () => {
    if (window.grecaptcha && _widgetID === undefined) {
      const wrapper = document.createElement("div")

      _widgetID = window.grecaptcha.render(wrapper, {
        sitekey: process.env.GRECAPTCHA_V2_KEY,
        callback: handleRecaptchaV2Response,
        theme: "light",
      })

      captchaRef.appendChild(wrapper)
    } else {
      // console.log("renderRecaptchaV2 fail")
    }
  }
  const createSubscriptionWithCaptcha = async () => {
    let _greToken, v2
    if (greToken) {
      v2 = true
      _greToken = greToken
      createSubscription(_greToken, v2)
    } else {
      v2 = false
      try {
        _greToken = await window.grecaptcha.execute(
          process.env.GRECAPTCHA_KEY,
          {
            action: "submit",
          }
        )
        createSubscription(_greToken, v2)
      } catch (e) {
        console.error(e)
        console.error("Fallback to recaptcha v2")
        return renderRecaptchaV2()
      }
    }
  }
  const handleRef = ref => {
    if (!ref) {
      return
    }
    captchaRef = ref
  }

  const { promo, subTotal, stripeEnabled, paymentMethod } = props

  const handleConifyPaymentTimeout = () => {
    setTimeout(() => {
      if (
        paymentMethod === METHODS.COINIFY ||
        paymentMethod === METHODS.CRYPTOMUS ||
        paymentMethod === METHODS.BITPAY ||
        paymentMethod === METHODS.NOWPAYMENTS ||
        paymentMethod === METHODS.BLOCKONOMICS
      ) {
        window.location.reload()
      }
    }, 60 * 60 * 1000)
  }

  const createSubscription = useCallback(
    async (greToken, v2) => {
      try {
        const code = get(promo, "id")
        const params = {
          features: selectedFeatures,
          billingCycle: selectedBillingCycle,
          locationID,
          ...(couponId && { couponId }),
        }

        if (multilocationId) {
          params["multilocationId"] = multilocationId
        }
        // Case 1: Credit >= Invoice making current total 0
        if (parseFloat(subTotal) <= 0) {
          setPurchasing(true)
          const res = await createSubscriptionPromise({
            promo: code,
            creditPayment: true,
            ...params,
          })
          if (res.data.payment_success || res.data.success) {
            handleSuccess()
          } else if (res.data.error) {
            toastError(res.data.error)
          }

          return setPurchasing(false)
        }

        // Case 2: Credit < Invoice && Coinbase/Coinify is chosen
        if (!stripeEnabled) {
          // CREDIT CARD IS CHOOSEN
          if (paymentMethod === METHODS.CREDITCARD) {
            setPurchasing(true)
            const res = await createNMICheckout({
              features: selectedFeatures,
              billingCycle: selectedBillingCycle,
              locationID,
              ...creditCardPaymentDetails,
            })
            if (res && res.status === 200) {
              handleSuccess()
            }
            setPayWithCardModel(false)
            setPurchasing(false)
            setCreditCardPaymentDetails(null)
            return
          }

          // Coinbase IS CHOOSEN
          // Coinify IS CHOOSEN
          if (paymentMethod === METHODS.COINIFY) {
            if (
              !window.confirm(
                "You will now be taken to coinify.com checkout. It usually takes a few " +
                  "minutes for the transfer to reflect in your account. This page will " +
                  "remain unresponsive during that time. You may either choose to wait or " +
                  "close/refresh this page and come back later to complete the purchase " +
                  "manually. Your funds will be stored safely in your account."
              )
            ) {
              setPurchasing(false)
              return
            }
            setPurchasing(true)
            const res = await createCoinifyCheckout({
              ...params,
              signatureEmail: mpEmail,
            })
            const checkoutUrl = res && res.url
            if (!checkoutUrl) {
              setPurchasing(false)
              return
            }
            const checkoutTag = document.createElement("a")
            checkoutTag.setAttribute("href", checkoutUrl)
            checkoutTag.setAttribute("target", "_blank")
            checkoutTag.click()

            const userID = get(authStore, "state.user.id")
            const socket = io.connect(process.env.RP_SOCKET_URL, {
              query: `userID=${userID}`,
            })
            socket.on("message", data => {
              setPurchasing(false)

              if (data.success) {
                handleSuccess()
              }
              if (!data.success && data.error) {
                let errorMsg =
                  "Something went wrong. Please contact our chat suppor team"
                if (data.error.includes("failed_underpaid")) {
                  errorMsg =
                    "The transaction could not be completed, please try again"
                }
                if (data.error.includes("failed_expired")) {
                  errorMsg =
                    "The transaction failed. Please contact our chat support team before trying again."
                }
                toastError(errorMsg)
              }
              socket.close()
            })
            let redirectUrl = window.location.search
            if (couponId) {
              redirectUrl += `&couponCode=${couponCode}&discount=${couponCodeDetails.discount}`
            }

            localStorage.setItem("paymentRedirect", redirectUrl)
            localStorage.setItem("paymentType", "subscription")
            handleConifyPaymentTimeout()
            return true
            // return navigate("/proxies", { replace: true })
          }

          // Cryptomus IS CHOOSEN
          if (paymentMethod === METHODS.CRYPTOMUS) {
            if (
              !window.confirm(
                "You will now be taken to cryptomus.com checkout. It usually takes a few " +
                  "minutes for the transfer to reflect in your account. This page will " +
                  "remain unresponsive during that time. You may either choose to wait or " +
                  "close/refresh this page and come back later to complete the purchase " +
                  "manually. Your funds will be stored safely in your account."
              )
            ) {
              setPurchasing(false)
              return
            }
            setPurchasing(true)
            const res = await createCryptomusCheckout({
              ...params,
              signatureEmail: mpEmail,
              recurringPayments: recurringPayment,
            })
            const checkoutUrl = res && res.url
            if (!checkoutUrl) {
              setPurchasing(false)
              return
            }
            const checkoutTag = document.createElement("a")
            checkoutTag.setAttribute("href", checkoutUrl)
            checkoutTag.setAttribute("target", "_blank")
            checkoutTag.click()

            const userID = get(authStore, "state.user.id")
            const socket = io.connect(process.env.RP_SOCKET_URL, {
              query: `userID=${userID}`,
            })
            socket.on("message", data => {
              setPurchasing(false)

              if (data.success) {
                handleSuccess()
              }
              if (!data.success && data.error) {
                let errorMsg =
                  "Something went wrong. Please contact our chat suppor team"
                if (data.error.includes("failed_underpaid")) {
                  errorMsg =
                    "The transaction could not be completed, please try again"
                }
                if (data.error.includes("failed_expired")) {
                  errorMsg =
                    "The transaction failed. Please contact our chat support team before trying again."
                }
                toastError(errorMsg)
              }
              socket.close()
            })
            let redirectUrl = window.location.search
            if (couponId) {
              redirectUrl += `&couponCode=${couponCode}&discount=${couponCodeDetails.discount}`
            }
            localStorage.setItem("paymentRedirect", redirectUrl)
            localStorage.setItem("paymentType", "subscription")
            handleConifyPaymentTimeout()
            return true
            // return navigate("/proxies", { replace: true })
          }

          // Bitpay IS CHOOSEN
          if (paymentMethod === METHODS.BITPAY) {
            if (
              !window.confirm(
                "You will now be taken to bitpay.com checkout. It usually takes a few " +
                  "minutes for the transfer to reflect in your account. This page will " +
                  "remain unresponsive during that time. You may either choose to wait or " +
                  "close/refresh this page and come back later to complete the purchase " +
                  "manually. Your funds will be stored safely in your account."
              )
            ) {
              setPurchasing(false)
              return
            }
            setPurchasing(true)
            const res = await createBitpayCheckout({
              ...params,
              signatureEmail: mpEmail,
            })
            const checkoutUrl = res && res.url
            if (!checkoutUrl) {
              setPurchasing(false)
              return
            }
            const checkoutTag = document.createElement("a")
            checkoutTag.setAttribute("href", checkoutUrl)
            checkoutTag.setAttribute("target", "_blank")
            checkoutTag.click()

            const userID = get(authStore, "state.user.id")
            const socket = io.connect(process.env.RP_SOCKET_URL, {
              query: `userID=${userID}`,
            })
            socket.on("message", data => {
              setPurchasing(false)

              if (data.success) {
                handleSuccess()
              }
              if (!data.success && data.error) {
                let errorMsg =
                  "Something went wrong. Please contact our chat suppor team"
                if (data.error.includes("failed_underpaid")) {
                  errorMsg =
                    "The transaction could not be completed, please try again"
                }
                if (data.error.includes("failed_expired")) {
                  errorMsg =
                    "The transaction failed. Please contact our chat support team before trying again."
                }
                toastError(errorMsg)
              }
              socket.close()
            })
            localStorage.setItem("paymentRedirect", window.location.search)
            localStorage.setItem("paymentType", "subscription")
            handleConifyPaymentTimeout()
            return true
            // return navigate("/proxies", { replace: true })
          }

          if (paymentMethod === METHODS.NOWPAYMENTS) {
            if (
              !window.confirm(
                "You will now be taken to nowpayments.io checkout. It usually takes a few " +
                  "minutes for the transfer to reflect in your account. This page will " +
                  "remain unresponsive during that time. You may either choose to wait or " +
                  "close/refresh this page and come back later to complete the purchase " +
                  "manually. Your funds will be stored safely in your account."
              )
            ) {
              setPurchasing(false)
              return
            }
            setPurchasing(true)
            const res = await createNowPaymentsCheckout({
              ...params,
              signatureEmail: mpEmail,
            })
            const checkoutUrl = res && res.url
            if (!checkoutUrl) {
              setPurchasing(false)
              return
            }
            const checkoutTag = document.createElement("a")
            checkoutTag.setAttribute("href", checkoutUrl)
            checkoutTag.setAttribute("target", "_blank")
            checkoutTag.click()

            const userID = get(authStore, "state.user.id")
            const socket = io.connect(process.env.RP_SOCKET_URL, {
              query: `userID=${userID}`,
            })
            socket.on("message", data => {
              setPurchasing(false)

              if (data.success) {
                handleSuccess()
              }
              if (!data.success && data.error) {
                let errorMsg =
                  "Something went wrong. Please contact our chat suppor team"
                if (data.error.includes("failed_underpaid")) {
                  errorMsg =
                    "The transaction could not be completed, please try again"
                }
                if (data.error.includes("failed_expired")) {
                  errorMsg =
                    "The transaction failed. Please contact our chat support team before trying again."
                }
                toastError(errorMsg)
              }
              socket.close()
            })
            localStorage.setItem("paymentRedirect", window.location.search)
            localStorage.setItem("paymentType", "subscription")
            handleConifyPaymentTimeout()
            return true
          }

          // BLOCKONOMICS IS CHOOSEN
          if (paymentMethod === METHODS.BLOCKONOMICS) {
            if (
              !window.confirm(
                "You will now be taken to blockonomics.io checkout. It usually takes a few " +
                  "minutes for the transfer to reflect in your account. This page will " +
                  "remain unresponsive during that time. You may either choose to wait or " +
                  "close/refresh this page and come back later to complete the purchase " +
                  "manually. Your funds will be stored safely in your account."
              )
            ) {
              setPurchasing(false)
              return
            }
            setPurchasing(true)
            const res = await createBlockonomicsCheckout({
              ...params,
              signatureEmail: mpEmail,
            })
            const checkoutUrl = res && res.url
            if (!checkoutUrl) {
              setPurchasing(false)
              return
            }
            const checkoutTag = document.createElement("a")
            checkoutTag.setAttribute("href", checkoutUrl)
            checkoutTag.setAttribute("target", "_blank")
            checkoutTag.click()

            const userID = get(authStore, "state.user.id")
            const socket = io.connect(process.env.RP_SOCKET_URL, {
              query: `userID=${userID}`,
            })
            socket.on("message", data => {
              setPurchasing(false)

              if (data.success) {
                handleSuccess()
              }
              if (!data.success && data.error) {
                let errorMsg =
                  "Something went wrong. Please contact our chat suppor team"
                if (data.error.includes("failed_underpaid")) {
                  errorMsg =
                    "The transaction could not be completed, please try again"
                }
                if (data.error.includes("failed_expired")) {
                  errorMsg =
                    "The transaction failed. Please contact our chat support team before trying again."
                }
                toastError(errorMsg)
              }
              socket.close()
            })
            localStorage.setItem("paymentRedirect", window.location.search)
            localStorage.setItem("paymentType", "subscription")
            handleConifyPaymentTimeout()
            return true
            // return navigate("/proxies", { replace: true })
          }

          if (
            !window.confirm(
              "You will now be taken to coinbase.com checkout. It usually takes a few " +
                "minutes for the transfer to reflect in your account. This page will " +
                "remain unresponsive during that time. You may either choose to wait or " +
                "close/refresh this page and come back later to complete the purchase " +
                "manually. Your funds will be stored safely in your account."
            )
          ) {
            setPurchasing(false)
            return
          }
          setPurchasing(true)
          const res = await createCoinbaseCheckout({
            ...params,
            promo: code,
            amount: subTotal,
            signatureEmail: mpEmail,
          })
          const checkoutUrl = res && res.url
          const checkoutTag = document.createElement("a")
          checkoutTag.setAttribute("href", checkoutUrl)
          checkoutTag.setAttribute("target", "_blank")
          checkoutTag.click()
          const userID = get(authStore, "state.user.id")
          const socket = io.connect(process.env.RP_SOCKET_URL, {
            query: `userID=${userID}`,
          })
          socket.on("message", data => {
            setPurchasing(false)

            if (data.success) {
              handleSuccess()
            }
          })

          return true
        }

        // Case 2: Credit < Invoice && Stripe is chosen
        setPurchasing(true)
        const res = await createStripeCheckout({
          ...params,
          gre_token: greToken, // TODO: refactor
          v2,
        })

        const { success, session_id: sessionId } = res.data

        if (success) {
          localStorage.setItem("stripeRedirect", window.location.search)
          stripe.redirectToCheckout({ sessionId })
        }

        setPurchasing(false)
      } catch (err) {
        toastError(
          get(
            err,
            "response.data.errors[0].detail",
            "Please wait for atleast 30 seconds before purchasing again"
          )
        )
        console.error("[Checkout]", err)
        setPurchasing(false)
      }
    },
    [
      handleSuccess,
      locationID,
      promo,
      props.pointsStore,
      selectedFeatures,
      setPurchasing,
      stripe,
      stripeEnabled,
      subTotal,
      selectedBillingCycle,
      multilocationId,
    ]
  )

  const handleClickPurchase = debounce(async () => {
    setPurchasing(true)
    createSubscriptionWithCaptcha()
  })

  const handleClick = e => {
    props.onClick(e)

    if (props.disabled) {
      return
    }
    if (!stripeEnabled) {
      if (paymentMethod === METHODS.CREDITCARD) {
        setPayWithCardModel(true)
        return
      }
      setCryptoActivationModal(true)
      return
    }
    handleClickPurchase()
  }

  const onCryptoActValidateSuccess = () => {
    handleClickPurchase()
    setCryptoActivationModal(false)
  }

  const handleCreditCardSuccess = async paymentDetails => {
    setCreditCardPaymentDetails({ ...paymentDetails })
  }

  return (
    <Container isMobile={props.isMobile} stripeEnabled={stripeEnabled}>
      <div className="recaptcha-container" ref={handleRef} />
      {/* <div>
        <img src={props.stripeEnabled ? stripeIcon : coinbaseLogo} />
      </div> */}
      <MyButton
        id={props.id}
        isMobile={props.isMobile}
        onClick={handleClick}
        disabled={purchasing}
        blueButton={props.blueButton}
      >
        {props.children}
      </MyButton>
      <CryptoActValidationModal
        isOpen={cryptoActivationModal}
        paymentMethod={paymentMethod}
        mpEmail={mpEmail}
        onHide={() => setCryptoActivationModal(false)}
        onSubmit={onCryptoActValidateSuccess}
      />
      {payWithCardModel && subTotal && (
        <PayWithCardModal
          isOpen={payWithCardModel}
          onHide={() => setPayWithCardModel(false)}
          onSuccess={handleCreditCardSuccess}
          amount={subTotal}
        />
      )}
    </Container>
  )
}
